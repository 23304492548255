<template>
	<div class="content-Block" :class="{ animate: animate }" :id="'content-Block-' + id" v-if="loaded">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="pushIn">
						<h2>{{ content.heading }}</h2>
						<div class="image">
							<img :src="content.imageURL" alt="" />
						</div>
						<p>{{ content.text }}</p>
						<a :href="content.buttonLink" target="_blank">
							<button>{{ content.button }}</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
	name: "Content Block",
	props: {
		content: Object,
	},
	setup() {
		/* ----- Data -------------------- */
		const loaded = ref(false);
		const animate = ref(false);
		const id = Math.floor(Math.random() * (999 - 1) + 1);

		/* ----- Methods -------------------- */
		const checkAnimation = () => {
			if (
				document.getElementById("content-Block-" + id).offsetTop - window.screen.height / 1.5 <
				window.scrollY
			) {
				animate.value = true;
			}
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;

			document.addEventListener("DOMContentLoaded", () => {
				checkAnimation();
			});
			document.addEventListener("scroll", () => {
				checkAnimation();
			});
		});

		return {
			loaded,
			animate,
			id,
			checkAnimation,
		};
	},
};
</script>

<style lang="scss" scoped>
.content-Block {
	position: relative;
	padding: 200px 0;
	background-color: var(--hive);

	h2 {
		font-size: 40px;
		font-weight: 700;
		color: var(--text);
		margin: 0;
		margin-bottom: 30px;

		transform: translateY(50px);
		opacity: 0;
	}
	p {
		font-size: 22px;
		font-weight: 400;
		color: var(--text);
		margin: 0;
		margin-bottom: 45px;

		transform: translateY(35px);
		opacity: 0;
	}
	button {
		transform: translateY(35px);
		opacity: 0;
	}
	.image {
		position: relative;
		width: 100%;
		margin-bottom: 30px;
		overflow: hidden;

		img {
			width: 100%;

			opacity: 0;
		}
	}
	.image:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--text);
		z-index: 2;
	}
}
.content-Block.animate {
	h2 {
		animation-name: h2;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0;
		animation-fill-mode: forwards;
	}
	p {
		animation-name: p;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.3s;
		animation-fill-mode: forwards;
	}
	button {
		animation-name: button;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.6s;
		animation-fill-mode: forwards;
	}
	.image {
		img {
			animation-name: img;
			animation-iteration-count: 1;
			animation-duration: 1s;
			animation-delay: 0s;
			animation-fill-mode: forwards;
		}
	}
	.image:after {
		animation-name: image;
		animation-iteration-count: 1;
		animation-duration: 2s;
		animation-delay: 0s;
		animation-fill-mode: forwards;
	}
}
@media only screen and (max-width: 768px) {
	.content-Block {
		padding-top: 45px;
		padding-bottom: 60px;
		button {
			width: 100%;
			text-align: center;
		}
	}

	/* ----- Animations -------------------- */
	@keyframes h2 {
		0% {
			transform: translateY(50px);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}
	@keyframes p {
		0% {
			transform: translateY(35px);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}
	@keyframes button {
		0% {
			transform: translateY(35px);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}
	@keyframes image {
		0% {
			top: 100%;
		}
		50% {
			top: 0;
		}
		100% {
			top: -100%;
		}
	}
	@keyframes img {
		0% {
			opacity: 0;
		}
		99% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
</style>
