<template>
	<headerBlock v-if="headerBlock" :content="headerBlock" />
	<headerBlock v-else :content="{ title: pageTitle, homePage: true }" />
	<div v-for="item in content.sort((a, b) => a.orderBy - b.orderBy)" :key="item.id">
		<textBlock
			v-if="item.blockType === 'Expanded'"
			:content="{
				heading: item.blockTitle,
				text: item.blockText,
			}"
		/>
		<sliderBlock
			v-if="item.blockType === 'Slider'"
			:content="{
				heading: 'HIVE zones',
				slides: singleSlides,
			}"
		/>
		<textVideoBlock
			v-if="item.blockType === 'videoBlock'"
			:content="{
				heading: item.blockTitle,
				text: item.blockText,
				buttons: [],
				videoURL: item.videos[0].fileURL,
				videoType: true,
				videoThumbail: item.videoThumbnail,
			}"
		/>
		<textImageBlock
			v-if="item.blockType === 'imageBlock'"
			:content="{
				heading: item.blockTitle,
				text: item.blockText,
				buttons: [],
				imageURL: item.images[0].fileURL,
				imageType: true,
			}"
		/>
	</div>

	<sliderBlock
		class="d-none"
		:content="{
			heading: 'HIVE Zones',
			slides: [
				{ id: 1, heading: 'Advanced Technology & Mobility', image: '/images/home-header-image.jpg' },
				{ id: 2, heading: 'E-commerce', image: '/images/home-header-image.jpg' },
				{ id: 3, heading: 'My Logisitics Innovation', image: '/images/home-header-image.jpg' },
				{ id: 4, heading: 'Healthcare', image: '/images/home-header-image.jpg' },
				{ id: 1, heading: 'Advanced Technology & Mobility', image: '/images/home-header-image.jpg' },
				{ id: 2, heading: 'E-commerce', image: '/images/home-header-image.jpg' },
				{ id: 3, heading: 'My Logisitics Innovation', image: '/images/home-header-image.jpg' },
				{ id: 4, heading: 'Healthcare', image: '/images/home-header-image.jpg' },
			],
		}"
	/>
	<textVideoBlock
		class="d-none"
		:content="{
			heading: 'A message from our CEO Carol B. Tome',
			text:
				'Vivamus non ultrices arce. Ut ultrices mauris sed molesie eleifend. Ut venenatis varius sapien sed bibendum. Pellentesque eget semper sapien.',
			button: 'Find out more',
			buttonLink: '#findoutmore',
			videoURL: 'f8XOxLeVg5s',
		}"
	/>
	<contentBlock
		class="d-none"
		:content="{
			heading: 'Inside the HIVE...',
			text:
				'Etiam ac risus condimentum, porta elit sit amet, mattis sapien. Curabitur sodales bibendum odio. Vivamus non ultrices arcu. Ut ultrices mauris sed molestie elelfend. Ut venenatis varius sapien sed bibemdum. Pellentesque eget semper sapien.',
			button: 'Find out more',
			buttonLink: '#findoutmore',
			imageURL: '/images/home-header-image.jpg',
		}"
	/>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";

import headerBlock from "@/components/blocks/header";
import textBlock from "@/components/blocks/text";
import sliderBlock from "@/components/blocks/slider";
import textVideoBlock from "@/components/blocks/textVideo";
import contentBlock from "@/components/blocks/content";
import textImageBlock from "@/components/blocks/textImage";

export default {
	name: "App",
	components: {
		headerBlock,
		textBlock,
		sliderBlock,
		textVideoBlock,
		contentBlock,
		textImageBlock,
	},
	setup() {
		const store = useStore();
		/* ----- DATA -------------------- */
		const pageTitle = ref(computed(() => store.state.pageContentHome.pageTitle));
		const headerBlock = ref(computed(() => store.state.pageContentHome.header));
		const content = ref(computed(() => store.state.pageContentHome.data));
		const navigation = ref(computed(() => store.state.navigation.menu));
		const singleSlides = ref([]);

		onMounted(() => {
			headerBlock.value.homePage = true;

			for (let i = 0; i < navigation.value.length; i++) {
				if (navigation.value[i].slider) {
					singleSlides.value.push(navigation.value[i]);
				}
			}
		});

		useHead({
			title: "Home" + store.state.siteTitle,
			siteTitle: " - Hive UPS",
			meta: [
				{
					name: `description`,
					content: "hello",
				},
			],
		});

		return {
			pageTitle,
			headerBlock,
			content,
			navigation,
			singleSlides,
		};
	},
};
</script>

<style lang="scss" scoped></style>
